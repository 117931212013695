<template>
  <div class="cultureMapDetailAlbumItem">
    <div
      class="cultureMapDetailAlbumItem__img"
      :style="`background-image: url(${baseURL}${article.image})`"
    ></div>
    <h3 class="cultureMapDetailAlbumItem__title">{{ article.title }}</h3>
    <p class="cultureMapDetailAlbumItem__text">
      {{ article.describ }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
      default: () => ({
        describ:
          '夏日晴空萬里，冬日煙雨朦朧。\r\n\r\n金瓜石彷彿千面女郎，\r\n\r\n隨著季節不同，展現千變萬化的風貌。\r\n\r\n寬廣的廟埕上，\r\n\r\n耆老正用宏亮嗓音，對著往來遊客，\r\n\r\n訴說山城過往的輝煌歲月。\r\n\r\n角落裡的駁坎，\r\n\r\n紀錄了古老細膩的建築工法，\r\n\r\n蔓生的蕨類正從縫隙中吐露新芽，\r\n\r\n展現自然的生命力。\r\n\r\n呼吸清新空氣，遙望飄渺山嵐，\r\n\r\n在懷舊的黑屋頂下，\r\n\r\n忘卻時間。\r\n\r\n讓我們跟隨在地達人的腳步，\r\n\r\n漫遊社區，\r\n\r\n貼身感受金瓜石的生活。',
        image: 'upload/stroll_map/56a6d79d4cb7.jpg',
        order: '0',
        title: '帶路人 瓜山里 張英傑里長'
      })
    }
  },
  computed: {
    baseURL() {
      return process.env.VUE_APP_WEB_BASE
    }
  }
}
</script>

<style lang="scss">
.cultureMapDetailAlbumItem {
  width: 100%;
  max-width: 70.05rem;
  margin: 6rem auto;

  &__img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding-bottom: 56.03%;
    margin-bottom: 2rem;
  }

  &__title::before,
  &__title::after {
    content: '';
    width: 2rem;
    height: 0.1rem;
    background-color: black;
    display: inline-block;
    margin-bottom: 0.8rem;
  }

  &__title::before {
    margin-right: 0.8rem;
  }

  &__title::after {
    margin-left: 0.8rem;
  }

  &__title {
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 4.1rem;
    text-align: center;
    margin-bottom: 0.8rem;
  }

  &__text {
    font-size: 1.8rem;
    line-height: 200%;
    color: #595757;
    text-align: justify;
    white-space: pre-line;
  }
}

@media (max-width: 768px) {
  .cultureMapDetailAlbumItem {
    width: 89%;
  }
}
</style>
