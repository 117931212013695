<template>
  <main v-if="mapItem" class="cultureMapDetail">
    <section class="cultureMapDetail__banner container">
      <div class="cultureMapDetail__titleContainer">
        <h2 class="cultureMapDetail__title">
          {{ mapItem.title1 }}
        </h2>
        <h2 class="cultureMapDetail__title">
          {{ mapItem.title2 }}
        </h2>
      </div>

      <p class="cultureMapDetail__info">
        ｜ {{ mapItem.region }} ｜ {{ mapItem.schedule }} ｜
        {{ mapItem.time }} ｜ {{ mapItem.traffic }} ｜
      </p>
    </section>

    <div v-if="pathList.length > 0" class="cultureMapDetail__progressbar">
      <div class="cultureMapDetail__progressContainer container">
        <div
          v-for="path in pathList"
          :key="path"
          class="cultureMapDetail__progressItem"
        >
          <address class="cultureMapDetail__progressLocation">
            {{ path }}
          </address>
          <div class="cultureMapDetail__progressDot"></div>
        </div>
      </div>
    </div>

    <div
      v-if="mapItem"
      class="cultureMapDetail__ckEditor container"
      v-html="mapItem.content.content"
    ></div>

    <div class="cultureMapDetail__bg"></div>

    <AlbumItem
      v-for="article in mapItem.article"
      :key="article.title"
      :article="article"
    />

    <GoBackFooter
      :routeItem="{
        text: '返回散步地圖',
        arrowString: '&#10229;',
        routeObj: { name: 'CultureMap' },
        useHistory: true
      }"
    />
  </main>
</template>

<script>
import AlbumItem from '@/components/CultureMapDetail/AlbumItem.vue'
import GoBackFooter from '@/components/GoBackFooter.vue'

// Api
import { apiGetStrollMap } from '@/api/webAPI'

export default {
  components: { AlbumItem, GoBackFooter },
  data() {
    return {
      mapItem: null
    }
  },
  computed: {
    pathList() {
      if (!this.mapItem || !this.mapItem.content.title) return []
      const splitResult = this.mapItem.content.title.split(',')

      return splitResult
    }
  },
  methods: {
    async fetchData() {
      const { id } = this.$route.query

      if (!id) {
        this.$router.push('CultureMap')
      }

      const response = (
        await apiGetStrollMap({
          type: 'article',
          id
        })
      ).data.data

      if (response.length === 0) {
        this.$router.push('CultureMap')
      }

      this.mapItem = response[0]
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style lang="scss">
.cultureMapDetail {
  overflow: hidden;

  &__banner {
    padding: 5.4rem 0 5.4rem 14rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__title {
    font-weight: 500;
    font-size: 3.7rem;
    line-height: 5.4rem;
    font-family: 'Noto Serif TC';
  }

  &__info {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-family: 'Noto Serif TC';
  }

  &__progressContainer {
    display: flex;
  }

  &__progressbar {
    position: relative;
    margin-bottom: 8.6rem;
  }

  &__progressLine {
    margin: 0;
    width: 100%;
    border: none;
  }

  &__progressItem {
    position: relative;
    text-align: center;
    flex: 1;
    margin-bottom: 1.6rem;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #595757;
    padding: 0 0.5rem;
    position: relative;
  }

  &__progressItem::after {
    content: '';
    border-bottom: 1px solid #d0a805;
    display: block;
    width: 500vw;
    left: -400%;
    position: absolute;
    bottom: -1.7rem;
  }

  &__progressDot {
    width: 1.2rem;
    height: 1.2rem;
    background-color: #d0a805;
    position: absolute;
    // dot 1.2rem / 2 = 0.6 + __progressItem margin-bottom 1.6 = 2.2rem
    bottom: -2.2rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 999px;
  }

  &__ckEditor,
  &__ckEditor * {
    font-size: 1.8rem !important;
    line-height: 200% !important;
    color: #595757 !important;
    font-family: 'Noto Sans TC', sans-serif !important;
  }

  &__ckEditor img {
    width: 100% !important;
    height: auto !important;
    max-width: 100rem !important;
    width: 100% !important;
    height: auto !important;
    display: block !important;
    margin: 0 auto !important;
  }

  &__bg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    background-image: url('~@/assets/image/CultureMapDetail/CultureMapDetailMountain.png');
    padding-bottom: 15%;
  }

  &__progressLocation {
    font-style: normal;
  }
}

@media (max-width: 768px) {
  .cultureMapDetail {
    &__banner {
      padding: 0;
      flex-wrap: wrap;
    }

    &__titleContainer {
      padding-top: 3.2rem;
      width: 100%;
      margin-bottom: 2rem;
    }

    &__info {
      margin-bottom: 2rem;
    }

    &__progressItem {
      width: 25%;
      font-size: 1.4rem;
      line-height: 2rem;
      flex: none;
      margin-bottom: 4rem;
    }

    &__progressContainer {
      flex-wrap: wrap;
    }

    &__progressbar {
      margin-bottom: 4rem;
    }
  }
}
</style>
